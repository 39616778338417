<template>
  <div class="d-flex flex-column">
    <LoadingOverlay v-if="this.isLoading"/>
    <router-view  v-if="!this.isLoading"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import LoadingOverlay from "@/views/components/LoadingOverlay";

export default {
  components: {LoadingOverlay},
  computed: {
    ...mapState(['isLoading']),
  },
  data() {
    return {}
  },
  watch: {},
};
</script>
<style>
</style>
