<template>
  <div class="dropdown">
    <button @click="toggleDropdown" class="dropdown-button">
      {{ dropDownName }} <span v-if="isDropdownOpen">▲</span><span v-else>▼</span>
    </button>
    <div v-if="isDropdownOpen" class="dropdown-menu">
      <div class="checkbox-group">
        <div
            v-for="(option, index) in options"
            :key="index"
            class="checkbox-item"
        >
          <label :class="{ selected: userSelectedCampaignOptions.some(selected => selected.displayName === option.displayName) }">
            <input
                type="checkbox"
                :value="option"
                v-model="selectedOptions"
                class="hidden-checkbox"
                @change="onCheckboxChange"
            />
            <span class="custom-checkbox"></span>
            {{ option.displayName }}
          </label>
        </div>
      </div>
      <div class="dropdown-actions">
        <button @click="clearAll" class="clear-button">Clear All</button>
        <button @click="apply" class="apply-button">Apply</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import CampaignHubService from "@/services/campaignHubService";

export default {
  name: "DropDownBox",
  props: {
    dropDownOptions: {
      type: Array,
      required: true,
    },
    dropDownName: {
      type: String,
      required: true,
    },
    isChannel: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      selectedOptions: []
    };
  },
  computed: {
    ...mapState(['isLoading', 'productConfig', 'userData', 'campaignChannelTable', 'userSelectedCampaignOptions']),
    options() {
      return this.dropDownOptions || [];
    },
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setCampaignChannelTable', 'setUserSelectedCampaignOptions']),
    onCheckboxChange() {
      this.setUserSelectedCampaignOptions(this.selectedOptions)
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async clearAll() {
      this.userSelectedCampaignOptions = [];
      const rows = this.isChannel ? await CampaignHubService.getChannels() : await CampaignHubService.getCampaigns();
      this.setCampaignChannelTable(rows)
      this.isDropdownOpen = false;
      this.setUserSelectedCampaignOptions([]);
    },
    filterMatchingCampaigns(campaigns, selected) {
      const selectedValues = selected.map(item => item.parameter);

      return campaigns.filter(campaign => {
        return Object.values(campaign).some(value =>
            selectedValues.includes(value)
        );
      });
    },
    async apply() {
      const campaigns = this.filterMatchingCampaigns(this.campaignChannelTable, this.userSelectedCampaignOptions)
      this.setCampaignChannelTable(campaigns)
      this.isDropdownOpen = false;
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.dropdown-button {
  background-color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #EE5C36;
  background: white;
  padding: 10px;
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  z-index: 1000 !important;
  width: 200px;
}

.checkbox-group {
  max-height: 150px;
  overflow-y: auto;
  padding-bottom: 10px;
}

.checkbox-item {
  margin: 5px 0;
}

.hidden-checkbox {
  display: none;
}

.custom-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background: white;
  vertical-align: middle;
  cursor: pointer;
}

label.selected .custom-checkbox {
  background: #EE5C36;
  border-color: #EE5C36;
}

.dropdown-actions {
  display: flex;
  justify-content: center;
}

.clear-button,
.apply-button {
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
}

.clear-button {
  background: #f5f5f5;
  border: 1px solid #ccc;
}

.apply-button {
  background: #EE5C36;
  border: none;
  color: white;
}
</style>
