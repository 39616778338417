import store from "@/store";

export async function userAuthenticate(to, from, next) {
    const user = JSON.parse(sessionStorage.getItem('user'));
    store.commit('setUserData', user)
    store.commit('setIsLoading', true);
    if (to.name !== 'LoginPage' && !store.state.userData) return next({name: 'LoginPage'})
    else next()
    store.commit('setIsLoading', false)
}
