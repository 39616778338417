<template>
  <div class="container-fluid login-container">
    <div class="row h-100">
      <div class="d-flex flex-column justify-content-center align-items-center col-sm-4 col-md-5 text-center">
        <img class="col-6" src="../../assets/penrose-logo.png">
        <div class="mt-2 slogan-text">Life's a Game. Let's Start Winning.</div>
        <div class="mt-3 welcome-text">Welcome</div>
        <div class="d-flex flex-column align-items-center" v-if="!enterOtp">
          <div class="mt-4 email-address-text">Please enter your mobile number</div>
          <input @keypress.enter="requestOtp()" v-model="userMsisdn" class="mt-3" placeholder="Enter Mobile Number">
          <button @click="requestOtp()" class="mt-3 submit-button">Submit</button>
        </div>
        <div class="d-flex flex-column align-items-center" v-if="enterOtp">
          <div class="mt-4 email-address-text">Please enter your OTP</div>
          <input @keypress.enter="otpSubmitted()" v-model="userOtp" type="text" class="mt-3" placeholder="Enter OTP">
          <button @click="otpSubmitted()" class="mt-3 submit-button">Submit</button>
          <div class="mt-2" v-if="this.errorMessage">
            <div class="error-text">{{ errorMessage }}</div>
            <div @click="retryLoginClicked()" class="text-decoration-underline">Retry</div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center text-center col-sm-8 col-md-7">
        <img class="home-screen-image col-7 col-sm-10 col-md-9 col-xl-9 mt-3" src="../../assets/home-screen-icon.jpg">
      </div>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/services/userDataService";
import {mapMutations} from "vuex";
import {productConfigObjectMap} from "@/config/productConfig";

export default {
  name: "LoginPage",
  computed: {},
  data() {
    return {
      userMsisdn: undefined,
      userOtp: undefined,
      enterOtp: false,
      errorMessage: undefined,
    }
  },
  methods: {
    ...mapMutations(['setUserData', 'setUserProductAccess']),
    async requestOtp() {
      const noPlus = this.userMsisdn.replace(/[+]/g, "");
      const noZero = noPlus.replace(/^[0]/, "27");
      this.userMsisdn = noZero.replace(/\s/g, '')
      const sendOtpResponse = (await UserDataService.sendOtp(this.userMsisdn)).data
      if (sendOtpResponse !== "") {
        const userData = sendOtpResponse[1];
        sessionStorage.setItem('user', JSON.stringify(userData));
        const userProductAccess = sendOtpResponse[2];
        this.setUserData(userData);
        this.setUserProductAccess(userProductAccess)
        this.routeToNetworkProduct(userData, userProductAccess);
      } else {
        this.enterOtp = true;
      }
    },
    routeToNetworkProduct(userData, userProductAccess) {
      let endpoint;

      if (userProductAccess) {
        endpoint = this.getProductEndpoint(userProductAccess.ProductId);
      } else {
        const networkEndpoints = {
          Vodacom: 'quiz-inc',
          HyveMobile: 'quiz-inc',
          MTN: 'mzansi-games',
          Derisk: 'voucher-awards',
          Masterstream: 'master-stream',
          Digitata: 'game-to-win'
        };

        const matchingNetwork = Object.keys(networkEndpoints).find(network => userData[network]);
        endpoint = matchingNetwork ? networkEndpoints[matchingNetwork] : null;
      }

      this.$router.push(`/dashboard/${endpoint}`);
    },
    getProductEndpoint(productId) {
      for (const [key, value] of productConfigObjectMap) {
        if (value.productId === productId) {
          return value.endpoint;
        }
      }
    },
    async otpSubmitted() {
      const loginUserResponse = (await UserDataService.loginUser(this.userMsisdn, this.userOtp)).data;
      if (loginUserResponse.InvalidOTP) {
        this.errorMessage = 'Invalid OTP';
      } else if (loginUserResponse[0].ValidOTP) {
        const userData = loginUserResponse[1];
        const userProductAccess = loginUserResponse[2];
        this.setUserData(userData);
        sessionStorage.setItem('user', JSON.stringify(userData));
        this.setUserProductAccess(userProductAccess)
        this.routeToNetworkProduct(userData, userProductAccess);
      }
    },
    retryLoginClicked() {
      location.reload();
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&display=swap');

.login-container {
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
}

.slogan-text {
  font-size: 20px;
  font-weight: 600;
}

.welcome-text {
  font-size: 40px;
}

.email-address-text {
  font-size: 18px;
}

input {
  height: 35px;
  width: 250px;
  border: 0.5px solid gray;
  border-radius: 16px;
  box-shadow: 0 0 3px;
  padding-left: 7px;
}

::placeholder {
  font-weight: 600;
  text-align: center;
  color: #000000FF;
}

.submit-button {
  font-weight: 600;
  height: 35px;
  width: 250px;
  border: 0;
  border-radius: 16px;
  color: #FFFFFF;
  background-color: #EE5C36;
}

.error-text {
  color: #ff0000;
}

</style>
